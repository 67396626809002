import React, { ReactElement } from "react";
import MainBanner from "@components/cardprocessingoffers/mainBanner";
import CardProcessorOfferWrapper from "@components/layouts/cardProcessorOffersWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

const OffersList = dynamic(() => import("@components/shared/offersList"));
const WhyUs = dynamic(() => import("@components/cardprocessingoffers/whyUs"));
const CardProcessor = dynamic(
    () => import("@components/cardprocessingoffers/creditCardProcessor"),
);
const PickRightService = dynamic(
    () => import("@components/cardprocessingoffers/pickRightService"),
);

export default function CardProcessingOffersHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <CardProcessorOfferWrapper>
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <MainBanner />
                <section>
                    <OffersList offers={domain?.defaultOffers} />
                </section>
                <CardProcessor />
            </div>

            <WhyUs />
            <PickRightService />
        </CardProcessorOfferWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
